<template>
  <va-card class="map-widget" :title="$t('dashboard.mti_map.label')">
    <div class="map-chart">
      <l-map :zoom="zoom" :center="center">
        <l-tile-layer :url="url"></l-tile-layer>
        <va-inner-loading :loading="loading">
          <l-marker
            v-for="(marker, index) in markers"
            :key="index"
            :lat-lng="marker[1]"
          >
            <l-tooltip>{{ marker[0] }}</l-tooltip>
          </l-marker>
        </va-inner-loading>
      </l-map>
    </div>
  </va-card>
</template>

<script>
import { LMap, LTileLayer, LMarker, LTooltip } from "vue2-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  name: "dashboard-map-chart",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
  },

  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 2,
      center: [47.31322, -1.319482],
      markers: [],
      loading: true,
    };
  },
  methods: {
    async getMarkers() {
      try {
        const response = await this.$http.get("mti/geolocalization");
        const responseData = response.data;

        this.markers = responseData.data.map((item) => [
          item.name,
          [item.latitude, item.longitude],
        ]);

        this.markers = this.markers.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) => t[1][0] === item[1][0] && t[1][1] === item[1][1]
            )
        );

        this.markers = this.markers.filter((item) => item[0] !== null);
      } catch (error) {
        console.error("Error fetching markers:", error);
      }
    },
  },
  mounted() {
    this.getMarkers();
    this.loading = false;
  },
};
</script>

<style lang="scss">
.map-widget {
  li {
    span {
      float: right;
    }
  }
}

.map-chart {
  height: 600px;
}
</style>
